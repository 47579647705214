import React, { useState, useContext, useEffect } from 'react'
import { FirebaseContext } from '../contexts/firebase'
import { navigate } from '@reach/router'
import copy from 'copy-text-to-clipboard'
import styled from '@emotion/styled'
import {
  ComputerPaper,
  ComputerPaperContent,
  ComputerPaperSide,
} from '../components/common/computer-paper'
import { Input } from '../components/common/form'
import Button from '../components/common/button'
import Directions from '../components/common/directions'

const UrlCopy = styled(Input)`
  font-family: monospace;
  width: 90%;
  padding: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
`

const UrlWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 2rem 0;
`

const CopyButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

const PlayersList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  li {
  }
`

const Setup = ({ gameId }) => {
  const [game, setGame] = useState(false)
  const [copied, setCopied] = useState(false)
  const [flyOut, setFlyOut] = useState()
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot((doc) => {
        setGame({ _id: doc.id, ...doc.data() })
      })
  }, [firebase, gameId])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 4000)
    }
  }, [copied])

  return (
    <>
      {game && (
        <ComputerPaper flyOut={flyOut}>
          <ComputerPaperSide />
          <ComputerPaperContent>
            <>
              <h1>Setup {game.name}</h1>
              <Directions extraMargin>
                Copy and send this URL to your friends so they can join.
              </Directions>
              <UrlWrapper>
                <UrlCopy
                  type="text"
                  defaultValue={
                    window.location.hostname === 'localhost'
                      ? `http://localhost:3000/join/${game._id}`
                      : `https://play.writeydrawey.com/join/${game._id}`
                  }
                />
                <CopyButton
                  blue
                  onClick={() => {
                    copy(
                      window.location.hostname === 'localhost'
                        ? `http://localhost:3000/join/${game._id}`
                        : `https://play.writeydrawey.com/join/${game._id}`
                    )
                    setCopied(true)
                  }}
                >
                  {copied ? <>Copied</> : <>Copy</>}
                </CopyButton>
              </UrlWrapper>
              <h2>
                {game.players.length > 1 && <>{game.players.length}</>} Players
              </h2>
              <PlayersList>
                {game.playerNames.map((player) => (
                  <li>{player}</li>
                ))}
              </PlayersList>
              <Directions extraMargin>
                Once everyone has joined, click the button to start the game.
              </Directions>
              {game.players.length < 3 && (
                <Directions extraMargin>
                  You cannot start until there are at least three players.
                </Directions>
              )}
              <Button
                blue
                disabled={game.players.length < 3}
                onClick={(event) => {
                  event.preventDefault()
                  setFlyOut(true)
                  setTimeout(() => {
                    firebase
                      .firestore()
                      .collection('games')
                      .doc(game._id)
                      .update({
                        status: 'started',
                        order: [...game.players.keys()].sort(() =>
                          Math.random() > 0.5 ? 1 : -1
                        ),
                      })
                      .then(navigate(`/play/${game._id}`))
                  }, 750)
                }}
              >
                Start game {game.players.length < 3 && <>(disabled)</>}
              </Button>
            </>
          </ComputerPaperContent>
          <ComputerPaperSide />
        </ComputerPaper>
      )}
    </>
  )
}

export default Setup
