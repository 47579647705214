import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/react'
import bp from '../../style/breakpoints'

const flyOut = keyframes`
100% {
  transform: translate3d(400%,40px,0) rotate(20deg);
}
`

const flyIn = keyframes`
0%{
  transform: translate3d(-200%, 0, 0) rotate(-20deg);
  box-shadow: 0 0 40px rgba(0, 0, 0, 1); 
}


100% {
  transform: translate3d(0,0,0) rotate(0deg);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
`

const Paper = styled.div`
  background: white;
  position: relative;
  display: flex;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  ${(props) =>
    props.hover &&
    `&:hover {
    border: 3px solid pink;
    box-shadow: 0 0 3px pink;
  }`}
  ${(props) => props.pointer && `cursor: pointer;`}
  ${(props) =>
    props.stacked &&
    `
    position: absolute;
    top: ${props.offset}px;
  `}
  ${(props) =>
    bp({
      width: [
        props.stacked ? '100%' : 'auto',
        props.stacked ? '100%' : 'auto',
        props.stacked ? '100%' : 'auto',
        '800px',
      ],
    })}
  ${(props) =>
    props.theme &&
    `background: ${props.theme.card.background};
  color: ${props.theme.card.color};`}
  ${bp({
    margin: ['1rem 0', '1rem 1rem', '1rem 1rem', '1rem auto'],
    borderRadius: ['0', '0 15px 15px 0;'],
  })}
  ${(props) =>
    props.animation &&
    css`
      animation: ${props.animation} 0.75s ease-in;
      animation-fill-mode: forwards;
    `}
  ${(props) =>
    props.flyOut &&
    css`
      animation: ${flyOut} 0.75s ease-in;
      animation-fill-mode: forwards;
    `}
  ${(props) =>
    props.flyIn &&
    css`
      animation: ${flyIn} 0.75s ease-out;
      animation-fill-mode: forwards;
    `}
`

const PaperContent = styled.div`
  padding: 1.5rem;
  ${bp({ paddingLeft: ['0.5rem', '0.5rem', '1.5rem'] })}
  border-left: 2.5px solid pink;
  min-height: 50vh;
  width: 100%;
`

const PaperHole = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  left: 15px;
  ${(props) =>
    props.white
      ? `background: white;`
      : `background: ${props.theme.background};`}
  &:first-of-type {
    top: 30px;
  }
  &:nth-of-type(2) {
    top: calc(50% - 10px);
  }

  &:last-of-type {
    bottom: 30px;
  }
`

const PaperSide = styled.div`
  width: 60px;
  position: relative;
  ${(props) => props.neverHide || bp({ display: ['none', 'none', 'block'] })}
  ${(props) =>
    props.theme &&
    `background: ${props.theme.card.background};
  color: ${props.theme.card.color};`}
`

export { Paper, PaperContent, PaperHole, PaperSide }
