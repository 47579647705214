/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import Atrament from 'atrament'
import styled from '@emotion/styled'
import Button from '../../components/common/button'

const DrawCanvas = styled.canvas`
  border: 2.5px solid ${(props) => props.theme && props.theme.text};
  border-radius: 4px;
  cursor: crosshair;
`

const DrawButtons = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
`

const Drawey = ({ done }) => {
  const canvasRef = useRef()
  const [strokes, setStrokes] = useState([])
  const [sketchpad, setSketchpad] = useState()

  useEffect(() => {
    const sketchpadInstance = new Atrament(canvasRef.current, {
      width:
        typeof window !== 'undefined' && window.innerWidth < 750 ? 350 : 600,
      height:
        typeof window !== 'undefined' && window.innerWidth < 750 ? 400 : 500,
      color: 'black',
    })
    sketchpadInstance.recordStrokes = true

    sketchpadInstance.addEventListener('strokerecorded', ({ stroke }) => {
      strokes.push(stroke)
      setStrokes(strokes)
    })
    setSketchpad(sketchpadInstance)
  }, [])

  const drawFeatures = () => {
    if (strokes.length === 0) {
      return
    }
    sketchpad.recordStrokes = false
    strokes.forEach((stroke) => {
      const points = stroke.points.slice()
      const firstPoint = points.shift()
      sketchpad.beginStroke(firstPoint.x, firstPoint.y)
      let previousPoint = firstPoint
      while (points.length > 0) {
        const point = points.shift()
        const { x, y } = sketchpad.draw(
          point.x,
          point.y,
          previousPoint.x,
          previousPoint.y
        )
        previousPoint = { x, y }
      }
      sketchpad.endStroke(previousPoint.x, previousPoint.y)
    })
    sketchpad.recordStrokes = true
  }

  return (
    <>
      <div>
        <DrawCanvas ref={canvasRef} />
      </div>

      <DrawButtons>
        <Button
          type="button"
          blue
          onClick={(event) => {
            event.preventDefault()
            done({ image: sketchpad.toImage() })
          }}
        >
          Done
        </Button>
        <Button
          muted
          onClick={() => {
            strokes.pop()
            setStrokes(strokes)
            sketchpad.clear()
            drawFeatures()
          }}
        >
          Undo
        </Button>
        <Button
          onClick={(event) => {
            sketchpad.clear()
          }}
        >
          Clear image
        </Button>
      </DrawButtons>
    </>
  )
}

export default Drawey
