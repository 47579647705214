import styled from '@emotion/styled'
import bp from '../../style/breakpoints'

const Card = styled.div`
  position: relative;
  ${bp({
    width: ['auto', 'auto', 'auto', '800px'],
    margin: ['1rem 1rem', '1rem 1rem', '1rem 1rem', '1rem auto'],
  })}
`

export default Card
