import React, { useEffect, useRef } from 'react'
import {
  Paper,
  PaperContent,
  PaperHole,
  PaperSide,
} from '../../components/common/paper'

const PlayCard = ({
  children,
  flyIn = false,
  flyOut = false,
  done = false,
}) => {
  const cardRef = useRef()
  useEffect(() => {
    if (done && flyOut) {
      void cardRef.current.offsetWidth
      cardRef.current.addEventListener('animationend', () => {
        done()
      })
    }
  }, [flyIn, done, flyOut])

  return (
    <Paper
      key={`play-card-${flyOut ? 'flying' : 'not-flying'}`}
      ref={cardRef}
      flyIn={flyIn}
      flyOut={flyOut}
      className={`card-${flyOut ? 'flying' : 'not-flying'}`}
    >
      <PaperSide>
        <PaperHole />
        <PaperHole />
        <PaperHole />
      </PaperSide>
      <PaperContent>{children}</PaperContent>
    </Paper>
  )
}

export default PlayCard
