import React from 'react'
import styled from '@emotion/styled'
import Quote from '../../components/common/quote'
import {
  Paper,
  PaperContent,
  PaperHole,
  PaperSide,
} from '../../components/common/paper'

const ViewImage = styled.img`
  max-width: 100%;
`

const buttonStyles = `
display: inline-block;
cursor: pointer;
font-size: 1.2rem;
border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
border: solid 7px #e6e6e4;
background: #e6e6e4;
text-decoration: none;
padding: 0.25rem;
color: #303030 !important;
font-family: 'Indie Flower', cursive;
`

const SaveButton = styled.a`
  ${buttonStyles}
`

const CloseButton = styled.a`
  ${buttonStyles}
  margin-right: 2rem;
`

const PaperButtons = styled.div`
  text-align: right;
`

const Page = ({ page, game, index, token, close }) => (
  <Paper stacked>
    <PaperSide>
      <PaperHole />
      <PaperHole />
      <PaperHole />
    </PaperSide>
    <PaperContent>
      <PaperButtons>
        <CloseButton
          onClick={(event) => {
            event.preventDefault()
            close()
          }}
        >
          View other pages
        </CloseButton>
        <SaveButton href={`/download-image?id=${game._id}&p=${index}`}>
          Save as image
        </SaveButton>
      </PaperButtons>
      <div>
        {page.map((sheet, sheetId) => (
          <>
            <h3>
              {sheet.player} {sheet.turn.text ? <>wrote:</> : <>drew:</>}
            </h3>
            {sheet.turn.text && <Quote>{sheet.turn.text}</Quote>}
            {sheet.turn.storedImage && (
              <p>
                <ViewImage
                  id={`img-${index}-${sheetId}`}
                  src={sheet.turn.storedImage}
                />
              </p>
            )}
          </>
        ))}
      </div>
    </PaperContent>
  </Paper>
)

export default Page
