import styled from '@emotion/styled'

const Brand = styled.img`
  font-size: 1.5rem;
  text-decoration: none;
  max-width: 300px;
  @media (min-width: 1000px) {
    max-width: 350px;
  }
`

export default Brand
