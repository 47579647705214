import React from 'react'
import styled from '@emotion/styled'
import Brand from './brand'
import Theme from '../../style/theme'

const Heading = styled.header`
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  max-width: 1100px;
  margin: 0 auto;
`

const Layout = ({ children, isHome, headless = false }) => (
  <Theme>
    <Heading>
      <Brand src="/logo.svg" alt="Writey Drawey" />
    </Heading>
    <main>{children}</main>
  </Theme>
)
export default Layout
