import React from 'react'
import styled from '@emotion/styled'
import bp from '../../style/breakpoints'
import Card from '../../components/common/card'
import Overview from '../../components/overview'

const OtherPlayers = styled.ul`
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
  font-size: 1.5rem;
  ${bp({ columns: [1, 3] })}
`

const Waiting = ({ game }) => {
  if (game.status !== 'created') {
    return null
  }
  return (
    <Card>
      <h2>Waiting on {game.playerNames[0]} to start the game</h2>
      <h3>Other players:</h3>
      <OtherPlayers>
        {game.playerNames.map((player) => (
          <li key={player}>{player}</li>
        ))}
      </OtherPlayers>
      <Overview />
    </Card>
  )
}

export default Waiting
