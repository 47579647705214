import React, { useContext } from 'react'
import { FirebaseContext } from '../../contexts/firebase'
import Directions from '../../components/common/directions'

const PlayerOrder = ({ game }) => {
  const { user } = useContext(FirebaseContext)
  if (game.status !== 'started') {
    return null
  }
  const startPlayer = game.order.indexOf(game.players.indexOf(user.uid))
  const playerList = [
    ...[...game.order].splice(startPlayer, game.order.length),
    ...[...game.order].splice(0, startPlayer),
  ]
  if (typeof game.players[playerList[1]] === 'undefined') {
    return null
  }
  return (
    <>
      <Directions extraMargin>
        Your page will be passed to{' '}
        <strong>{game.playerNames[playerList[1]]}</strong>
      </Directions>
    </>
  )
}

export default PlayerOrder
