import React, { useContext, useState } from 'react'
import { navigate } from '@reach/router'
import { FirebaseContext } from '../contexts/firebase'
import { Label, Input } from '../components/common/form'
import styled from '@emotion/styled'
import Directions from '../components/common/directions'
import { Button } from '../components/common/button'

import {
  ComputerPaper,
  ComputerPaperContent,
  ComputerPaperSide,
} from '../components/common/computer-paper'

const Donate = styled.p`
  margin-top: 5rem;
  text-align: right;
`

const Lede = styled.p`
  margin-bottom: 3rem;
`

const NewGame = () => {
  const [gameName, setGameName] = useState('')
  const [userName, setUserName] = useState('')
  const { firebase, user } = useContext(FirebaseContext)
  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault()
        let login = false
        if (!user) {
          login = await firebase.auth().signInAnonymously()
        }
        const owner = !user && login ? login.user.uid : user.uid
        firebase.analytics().logEvent('game_created')
        firebase
          .firestore()
          .collection('games')
          .add({
            owner,
            name: gameName,
            players: [owner],
            playerNames: [userName],
            currentTurn: 0,
            turns: [],
            pages: [],
            settings: {
              noPressure: false,
            },
            status: 'created',
            createdAt: new Date(),
          })
          .then((result) => {
            navigate(`/setup/${result.id}`)
          })
          .catch((error) => {
            console.error('Error writing document: ', error)
          })
      }}
    >
      <Label htmlFor="name" aria-labelledby="game-name-directions">
        Game name
      </Label>
      <Directions id="game-name-directions" extraMargin>
        The name of the game!
      </Directions>
      <Input
        type="text"
        name="name"
        id="name"
        placeholder="game name"
        onChange={(event) => setGameName(event.target.value)}
      />
      <Label htmlFor="you" aria-labelledby="name-directions">
        Your name
      </Label>
      <Directions id="name-directions" extraMargin>
        Choose a name for yourself. You get a chance to rename yourself for
        every game.
      </Directions>
      <Input
        type="text"
        name="you"
        placeholder="your name"
        onChange={(event) => setUserName(event.target.value)}
      />
      <Button
        type="submit"
        blue
        disabled={gameName.length === 0 || userName.length < 2}
      >
        Create
      </Button>
    </form>
  )
}

const Homepage = () => (
  <>
    <ComputerPaper>
      <ComputerPaperSide />
      <ComputerPaperContent>
        <Lede>
          Writey Drawey is in <strong>beta</strong>.
          <br /> Please{' '}
          <a
            href="https://airtable.com/shrCJmxwBfruvFAqx"
            rel="noreferrer"
            target="_blank"
          >
            let me know what you think
          </a>
          !
        </Lede>
        <h2>Start a new game</h2>
        <NewGame />
        <Donate>
          Enjoy Writey Drawey? Consider{' '}
          <a
            href="https://www.buymeacoffee.com/kevee"
            target="_blank"
            rel="noopener noreferrer"
          >
            buying me a coffee
          </a>
          .
        </Donate>
      </ComputerPaperContent>
      <ComputerPaperSide />
    </ComputerPaper>
  </>
)

export default Homepage
