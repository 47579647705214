import React from 'react'
import Card from './card'

const NotFound = () => (
  <Card>
    <h1>Not found</h1>
  </Card>
)

export default NotFound
