import React from 'react'
import { Global, css, ThemeProvider, useTheme } from '@emotion/react'

const themes = {
  light: {
    background: 'rgb(255, 241, 229)',
    text: '#303030',
    brand: '#303030',
    input: '#ffffff',
    notification: '#F55F6E',
    colors: {
      link: 'blue',
    },
    settings: {
      color: '#aaa',
      hover: '#303030',
    },
    card: {
      background: '#ffffff',
      color: '#303030',
    },
    waitingCard: {
      background: 'rgba(0,0,0,0.5);',
      color: '#fff',
    },
    directions: {
      color: '#737272',
    },
    button: {
      red: '',
      blue: '#6f6ff5',
      muted: 'rgb(226, 226, 225)',
    },
  },
}

const ThemeContent = ({ children }) => {
  const theme = useTheme()
  return (
    <>
      <Global
        styles={css`
          body {
            background: ${theme.background};
            color: ${theme.text};
          }
          a,
          a:visited,
          a:link {
            color: ${theme.colors.link};
          }
        `}
      />
      {children}
    </>
  )
}

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={themes.light}>
      <ThemeContent>{children}</ThemeContent>
    </ThemeProvider>
  )
}

export default Theme
