import React, { useEffect, useState, useContext } from 'react'
import { FirebaseContext } from '../../contexts/firebase'
import Waiting from './waiting'
import Round from './round'
import RoundDoneWaiting from './round-done'
import NotFound from '../../components/common/not-found'
import { navigate } from '@reach/router'

const getCurrentTurn = (game) => {
  if (game.turns.length === 0) {
    return false
  }

  return game.turns.findIndex((turn) => !turn.done)
}

const Play = ({ game }) => {
  const { user } = useContext(FirebaseContext)
  const currentPlayer = game.players.indexOf(user.uid)
  const isWaiting = (() => {
    const current = getCurrentTurn(game)
    if (current === false || current === -1) {
      return false
    }
    return (
      typeof game.turns[current].players !== 'undefined' &&
      typeof game.turns[current].players[currentPlayer] !== 'undefined'
    )
  })()
  return (
    <>
      {isWaiting ? (
        <RoundDoneWaiting game={game} />
      ) : (
        <>
          <Waiting game={game} />
          <Round game={game} />
        </>
      )}
    </>
  )
}

const PlayPage = ({ gameId }) => {
  const [game, setGame] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setGame({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
  }, [firebase, gameId])

  useEffect(() => {
    if (game && game.status === 'done') {
      navigate(`/view/${game._id}`)
    }
  }, [game])

  return (
    <>
      {!notFound && game && <Play game={game} />}
      {notFound && <NotFound />}
    </>
  )
}

export default PlayPage
