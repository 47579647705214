import React from 'react'
import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/react'
import bp from '../../style/breakpoints'

const flyOut = keyframes`
100% {
  transform: translate3d(0,300%,0) rotate(20deg);
}
`

const flyIn = keyframes`
0%{
  transform: translate3d(0, 300%, 0) rotate(30deg);
}


100% {
  transform: translate3d(0,0,0) rotate(0deg);
}
`

const ComputerPaper = styled.div`
  background: white;
  position: relative;
  ${bp({ height: ['auto', 'auto', '100vh'] })}
  overflow: hidden;

  display: flex;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  ${(props) =>
    props.theme &&
    `background: ${props.theme.card.background};
  color: ${props.theme.card.color};`}
  ${bp({
    width: ['auto', 'auto', 'auto', '800px'],
    margin: ['1rem 0', '1rem 1rem', '1rem auto'],
  })}

  ${(props) =>
    props.flyOut &&
    css`
      animation: ${flyOut} 0.75s ease-in;
      animation-fill-mode: forwards;
    `}
  ${(props) =>
    props.flyIn &&
    css`
      animation: ${flyIn} 0.75s ease-out;
      animation-fill-mode: forwards;
    `}
`

const ComputerPaperContent = styled.div`
  ${bp({
    padding: ['0.5rem 1rem', '1rem', '1.5rem'],
    border: ['0', '0', '1.5px dashed grey'],
  })}

  border-top: 0!important;
  border-bottom: 0 !important;
  min-height: 50vh;
  width: 100%;
`

const ComputerPaperHole = styled.div`
  ${(props) => props.theme && `background: ${props.theme.background}`};
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin: 20px auto;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
`

const ComputerPaperSideElement = styled.div`
  width: 60px;
  position: relative;
  ${(props) =>
    props.theme &&
    `background: ${props.theme.card.background};
  color: ${props.theme.card.color};`}
  ${bp({ display: ['none', 'none', 'block'] })}
  overflow: hidden;
`

const ComputerPaperSide = () => {
  const holes = []
  for (let i = 0; i < 30; i++) {
    holes.push(<ComputerPaperHole key={`hole-paper-${i}`} />)
  }
  return <ComputerPaperSideElement>{holes}</ComputerPaperSideElement>
}

export {
  ComputerPaper,
  ComputerPaperContent,
  ComputerPaperHole,
  ComputerPaperSide,
}
