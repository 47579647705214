import styled from '@emotion/styled'

const Quote = styled.blockquote`
  font-size: 2rem;
  line-height: 2rem;
  margin: 1rem 0;
  font-family: 'Indie Flower', cursive;
`

export default Quote
