import React, { useState } from 'react'
import styled from '@emotion/styled'
import Button from '../common/button'
import Quote from '../common/quote'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Paper, PaperContent, PaperHole, PaperSide } from '../common/paper'

const Paragraph = styled.p`
  font-size: 1.5rem;
`

const SampleImage = styled.img`
  max-width: 250px;
`
const NextButton = styled(Button)`
  float: right;
`

const steps = [
  () => (
    <>
      <Paragraph>
        Writey drawey is like a game of telephone but with words and pictures.
      </Paragraph>
    </>
  ),
  () => (
    <>
      <Paragraph>
        Everyone starts by writing a sentence or two, then pass that paper to
        the next player:
      </Paragraph>
      <Quote>The magical dog cast a spell for a million tennis balls.</Quote>
    </>
  ),
  () => (
    <>
      <Paragraph>
        You then draw a picture of what was written, fold the page to hide it,
        and pass it to the next player.
      </Paragraph>
      <div>
        <SampleImage src="/sample.png" alt="" aria-hidden />
      </div>
    </>
  ),
  () => (
    <>
      <Paragraph>
        Switch between pictures and descriptions until your page makes it back
        to you.
      </Paragraph>
      <Quote>Wizard horse versus the aliens!</Quote>
    </>
  ),
]

const Overview = () => {
  const [step, setStep] = useState(0)
  const [page, setPage] = useState({ flyIn: true, flyOut: false })

  return (
    <Paper flyIn={page.flyIn} flyOut={page.flyOut}>
      <PaperSide neverHide>
        <PaperHole />
        <PaperHole />
        <PaperHole />
      </PaperSide>
      <PaperContent>
        {typeof steps[step + 1] !== 'undefined' && (
          <NextButton
            blue
            aria-label="Next"
            onClick={() => {
              setPage({ flyIn: false, flyOut: true })
              setTimeout(() => {
                setStep(step + 1)
                setPage({ flyIn: true, flyOut: false })
              }, 750)
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </NextButton>
        )}
        {steps[step]()}
      </PaperContent>
    </Paper>
  )
}

export default Overview
