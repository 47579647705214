import React, { createContext, useState } from 'react'
import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/performance'

const firebaseConfig = {
  apiKey: 'AIzaSyB4pIihDnvgZIe0wv0oJy213cqUA5l2l-o',
  authDomain: 'writey-drawey.firebaseapp.com',
  databaseURL: 'https://writey-drawey-default-rtdb.firebaseio.com',
  projectId: 'writey-drawey',
  storageBucket:
    window.location.hostname === 'localhost'
      ? 'writey-drawey-test.appspot.com'
      : 'writey-drawey.appspot.com',
  messagingSenderId: '511747512796',
  appId: '1:511747512796:web:b50bb2cd141836e3185719',
  measurementId: 'G-ZSZQLFE01Y',
}

const FirebaseContext = createContext()

const app = firebase.initializeApp(firebaseConfig)

if (window.location.hostname === 'localhost') {
  firebase.auth().useEmulator('http://localhost:9099/')
  firebase.firestore().useEmulator('localhost', 8080)
  firebase.functions().useEmulator('localhost', 5001)
}

const FirebaseProvider = ({ children }) => {
  const [currentUser, setCurrentuser] = useState(false)
  firebase.auth().onAuthStateChanged((user) => {
    setCurrentuser(user)
  })
  const db = firebase.firestore()
  firebase.analytics()
  firebase.performance()

  const storage = firebase.storage().ref()
  return (
    <FirebaseContext.Provider
      value={{
        firebase: app,
        firestore: firebase.firestore,
        db,
        storage,
        user: currentUser,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

export { FirebaseProvider, FirebaseContext }
