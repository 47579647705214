import React, { useState, useContext } from 'react'
import { FirebaseContext } from '../../contexts/firebase'
import Writey from './writey'
import Drawey from './drawey'
import Prompt from './prompt'
import PlayCard from './play-card'
import PlayerOrder from './player-order'
import { randomBytes } from 'crypto'
import { DateTime } from 'luxon'

const Round = ({ game }) => {
  const { firebase, storage, user } = useContext(FirebaseContext)
  const [card, setCard] = useState({
    flyIn: true,
    flyOut: false,
    done: false,
  })

  if (game.status !== 'started') {
    return null
  }
  const currentPlayer = game.players.indexOf(user.uid)
  const isWritey = (game.turns.filter((turn) => turn.done).length + 1) % 2

  const saveTurn = async (type, turn) => {
    const turnTemplate = {
      done: false,
      current: true,
      start: new Date(),
      players: {},
    }

    const playerKey = game.players.indexOf(user.uid)
    const currentTurn = game.turns.filter((turn) => turn.done).length
    if (typeof game.turns[currentTurn] === 'undefined') {
      game.turns[currentTurn] = { ...turnTemplate }
    }
    if (type === 'writey') {
      turn.text = turn.text.replace(/(<([^>]+)>)/gi, '')
    }

    if (type === 'drawey') {
      const imageType = turn.image.search('image/png') > -1 ? 'png' : 'jpg'
      const random = await randomBytes(16).toString('hex')
      const imageUrl = `${DateTime.local().toISODate()}/${
        game._id
      }-${random}.${imageType}`
      const imageRef = storage.child(imageUrl)

      await imageRef.putString(
        turn.image.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      )
      delete turn.image
      turn.storedImage = await imageRef.getDownloadURL()
    }
    game.turns[currentTurn].players[playerKey] = turn
    if (
      Object.keys(game.turns[currentTurn].players).length ===
      game.players.length
    ) {
      game.turns[currentTurn].done = true
      game.turns[currentTurn].current = false
      if (game.turns.filter((turn) => turn.done).length < game.players.length) {
        game.turns[currentTurn + 1] = { ...turnTemplate }
      }
    }
    const status =
      game.turns.filter((turn) => turn.done).length === game.players.length
        ? 'done'
        : 'started'

    return firebase.firestore().collection('games').doc(game._id).update({
      turns: game.turns,
      status,
    })
  }

  const setTurn = (turn) => {
    setCard({
      flyIn: false,
      flyOut: true,
      done: () => {
        saveTurn(isWritey ? 'writey' : 'drawey', turn).then((result) => {
          console.log(result)
          setCard({
            flyIn: true,
            flyOut: false,
            done: () => {},
          })
        })
      },
    })
  }

  return (
    <>
      <PlayCard flyIn={card.flyIn} flyOut={card.flyOut} done={card.done}>
        <Prompt game={game} currentPlayer={currentPlayer} />
        {isWritey ? (
          <Writey done={(turn) => setTurn(turn)} />
        ) : (
          <Drawey done={(turn) => setTurn(turn)} />
        )}
        <PlayerOrder game={game} />
      </PlayCard>
    </>
  )
}

export default Round
