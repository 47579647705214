import React from 'react'

import { Router } from '@reach/router'
import { css, Global } from '@emotion/react'
import { FirebaseProvider } from './contexts/firebase'
import Layout from './components/layout'

import Homepage from './pages/index'
import Register from './pages/register'
import Setup from './pages/setup'
import Join from './pages/join'
import Play from './pages/play'
import View from './pages/view'
import NotFound from './pages/not-found'

const App = () => (
  <div className="App">
    <Global
      styles={css`
        :root {
          font-size: 18px;
        }

        body {
          font-family: 'system-ui', '-apple-system', 'BlinkMacSystemFont',
            'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

          font-size: 1rem;
          background: rgb(226, 226, 225);
          margin: 0;
          padding: 0;
        }

        textarea {
          font-family: 'system-ui', '-apple-system', 'BlinkMacSystemFont',
            'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
        }
      `}
    />
    <FirebaseProvider>
      <Layout>
        <Router>
          <Homepage path="/" />
          <Register path="register" />
          <Setup path="setup/:gameId" />
          <Join path="join/:gameId" />
          <Play path="play/:gameId" />
          <View path="view/:gameId" />
          <NotFound default />
        </Router>
      </Layout>
    </FirebaseProvider>
  </div>
)

export default App
