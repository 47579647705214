import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { FirebaseContext } from '../../contexts/firebase'

import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import Card from '../../components/common/card'
import {
  Paper,
  PaperContent,
  PaperHole,
  PaperSide,
} from '../../components/common/paper'
import NotFound from '../../components/common/not-found'
import Page from './page'
import Quote from '../../components/common/quote'
import PostIt from '../../components/common/post-it'

const ViewPaperWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const PaperHeader = styled.h2`
  margin: 0;
`

const PaperHeaderButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  display: block;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
`

const ViewPaper = ({ page, index, setPage, isLast = false }) => {
  const flyAnimation = keyframes`
  0%{
    transform: translate3d(-200%, 0, 0) rotate(-50deg);
  }


  100% {
    transform: translate3d(0,0,0) rotate(${page[0].degrees}deg);
  }
  `

  return (
    <Paper
      stacked
      pointer
      hover
      offset={index * 100}
      animation={flyAnimation}
      onClick={() => {
        setPage(index)
      }}
    >
      <PaperSide neverHide>
        <PaperHole white={index > 0} />
        <PaperHole white={index > 0} />
        <PaperHole white={index > 0} />
      </PaperSide>
      <PaperContent>
        <PaperHeader>
          <PaperHeaderButton
            onClick={(event) => {
              event.preventDefault()
              setPage(index)
            }}
          >
            {page[0].player}
          </PaperHeaderButton>
          {isLast && (
            <PostIt>
              I hope you enjoyed Writey Drawey! If you did, consider{' '}
              <a
                href="https://www.buymeacoffee.com/kevee"
                target="_blank"
                rel="noopener noreferrer"
              >
                buying me a coffee
              </a>
              !
            </PostIt>
          )}
        </PaperHeader>
      </PaperContent>
    </Paper>
  )
}

const View = ({ game }) => {
  const [currentPage, setCurrentPage] = useState(false)

  useEffect(() => {
    if (typeof document === 'undefined') {
      return
    }
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        setCurrentPage(false)
      }
    })
  })

  const pages = useMemo(() => {
    const results = []
    game.turns.forEach((turn, turnIndex) => {
      game.order.forEach((playerIndex, orderIndex) => {
        if (typeof results[orderIndex] === 'undefined') {
          results[orderIndex] = []
        }
        const nextPlayerIndex =
          orderIndex + turnIndex < game.order.length
            ? orderIndex + turnIndex
            : orderIndex + turnIndex - game.order.length
        results[orderIndex].push({
          player: game.playerNames[game.order[nextPlayerIndex]],
          turn: turn.players[game.order[nextPlayerIndex]],
          degrees:
            Math.floor(Math.random() * 5) * (Math.random() > 0.5 ? 1 : -1),
        })
      })
    })
    return results
  }, [game])

  const setPage = useCallback((page) => setCurrentPage(page), [])

  return (
    <>
      <Card>
        <h1>{game.name}</h1>
        {game.status !== 'done' && (
          <Quote>
            Sorry, you can&apos;t view this game, it isn't done yet!
          </Quote>
        )}
      </Card>
      {game.status === 'done' && (
        <ViewPaperWrapper>
          {pages.map((page, index) => (
            <ViewPaper
              key={`page-${index}`}
              page={page}
              index={index}
              gameId={game._id}
              setPage={(newPage) => setPage(newPage)}
              isLast={index === pages.length - 1}
            />
          ))}
          {currentPage !== false && (
            <Page
              page={pages[currentPage]}
              index={currentPage}
              game={game}
              close={() => {
                setCurrentPage(false)
              }}
            />
          )}
        </ViewPaperWrapper>
      )}
    </>
  )
}

const ViewPage = ({ gameId }) => {
  const [game, setGame] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setGame({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
  }, [firebase, gameId])

  return (
    <>
      {!notFound && game && <View game={game} />}
      {notFound && <NotFound />}
    </>
  )
}

export default ViewPage
