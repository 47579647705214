import styled from '@emotion/styled'

const Label = styled.label`
  font-weight: bold;
  display: inline;
`

const Input = styled.input`
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0px;
  margin-bottom: 1rem;
  min-width: 0px;
  display: block;
  width: 100%;
  padding: 8px;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;
  color: inherit;
  background-color: ${(props) => props.theme.input};
  border-color: grey;
`

const Help = styled.p`
  font-size: 0.75rem;
`

export { Label, Input, Help }
