import React from 'react'
import Card from '../components/common/card'

const NotFoundPage = () => (
  <Card>
    <h1>Not found</h1>
  </Card>
)

export default NotFoundPage
