import React from 'react'
import Card from '../../components/common/card'
import styled from '@emotion/styled'
import 'react-toggle/style.css'

const ShameList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    margin: 0.5rem 0;
  }
`

const RoundDoneWaiting = ({ game }) => {
  const isWritey = (game.turns.filter((turn) => turn.done).length + 1) % 2
  const waitingOn = game.playerNames.filter(
    (player, id) =>
      Object.keys(game.turns.find((turn) => turn.current).players)
        .map((i) => parseInt(i, 10))
        .indexOf(id) === -1
  )
  return (
    <Card>
      <h2>
        Waiting for everyone to finish {isWritey ? 'writing' : 'drawing'}:
      </h2>
      <ShameList>
        {waitingOn.map((player) => (
          <li>{player}</li>
        ))}
      </ShameList>
    </Card>
  )
}

export default RoundDoneWaiting
