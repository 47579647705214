import React, { useState } from 'react'
import styled from '@emotion/styled'
import TextareaAutosize from 'react-autosize-textarea'
import bp from '../../style/breakpoints'
import Button from '../../components/common/button'

const TextArea = styled(TextareaAutosize)`
  font-size: 2rem;
  display: block;
  line-height: 2.5rem;
  ${bp({ width: ['95%', '100%'] })};
  border: 2px solid ${(props) => props.theme && props.theme.text};
  font-family: 'Indie Flower', cursive;
  border-radius: 4px;
  margin: 2rem 0;
`

const Writey = ({ done }) => {
  const [text, setText] = useState('')
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        done({ text })
      }}
    >
      <TextArea
        rows={2}
        onChange={(event) => setText(event.target.value)}
        placeholder="Write something..."
      ></TextArea>
      <Button blue type="submit" disabled={text.length === 0}>
        Done
      </Button>
    </form>
  )
}

export default Writey
