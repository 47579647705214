import styled from '@emotion/styled'
import { css } from '@emotion/react'
import bp from '../../style/breakpoints'

const buttonStyles = css`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.5rem;
  text-decoration: none;
  box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, 0.2);
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  border: solid 7px #41403e;
  background: transparent;
  font-weight: bold;
  transition: all 0.5s ease;
  color: black;
  font-family: 'Indie Flower', cursive;
  ${bp({ fontSize: ['1rem', '1rem', '1.5rem'] })};
  &:hover {
    box-shadow: 2px 8px 4px -6px hsla(0, 0%, 0%, 0.3);
    opacity: 0.8;
  }
  &[disabled] {
    color: white;
    border-color: #b35363;
    background: #b35363;
    cursor: not-allowed;
  }
`

const Button = styled.button`
  ${buttonStyles}
  ${(props) =>
    props.blue &&
    `
    border-color: #6f6ff5;
    background: #6f6ff5;
    color: white;
  `}
`

const ButtonLink = styled.a`
  ${buttonStyles}
`

const ButtonUnstyled = styled.button`
  background: transparent;
  border: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
`

export default Button

export { buttonStyles, Button, ButtonLink, ButtonUnstyled }
