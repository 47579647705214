import React from 'react'
import styled from '@emotion/styled'
import Quote from '../../components/common/quote'

const Notification = styled.p`
  color: ${(props) => props.theme.notification};
`

const PromptImage = styled.img`
  max-width: 100%;
`

const PressureNotification = ({ game, currentTurn }) => {
  if (typeof game.turns[currentTurn] === 'undefined') {
    return null
  }
  return (
    <Notification>
      {Object.keys(game.turns[currentTurn].players).length ===
      game.players.length - 1 ? (
        <>
          <strong>Heads up:</strong> You are the last player in the round.
        </>
      ) : (
        <>&nbsp;</>
      )}
    </Notification>
  )
}

const Prompt = ({ game, currentPlayer }) => {
  const currentPlayerPosition = game.order.indexOf(currentPlayer)
  const priorPlayerOrderIndex =
    currentPlayerPosition - 1 >= 0
      ? currentPlayerPosition - 1
      : game.order.length - 1
  const priorPlayerIndex = game.order[priorPlayerOrderIndex]
  const currentTurn = game.turns.findIndex((turn) => turn.current)

  if (game.turns.length <= 1 || game.status !== 'started' || !game.order) {
    return <PressureNotification game={game} currentTurn={currentTurn} />
  }
  const priorTurn = game.turns[currentTurn - 1].players[priorPlayerIndex]
  const name = game.playerNames[priorPlayerIndex]
  return (
    <>
      <p>
        {priorTurn.text ? (
          <>
            Draw a picture of what <strong>{name}</strong> wrote:
          </>
        ) : (
          <>
            Describe this picture by <strong>{name}</strong>:
          </>
        )}
      </p>
      {priorTurn.text && <Quote>{priorTurn.text}</Quote>}
      {priorTurn.storedImage && (
        <p>
          <PromptImage src={`${priorTurn.storedImage}`} />
        </p>
      )}
      <PressureNotification game={game} currentTurn={currentTurn} />
    </>
  )
}

export default Prompt
