import React, { useContext, useState } from 'react'
import { navigate } from '@reach/router'
import { FirebaseContext } from '../contexts/firebase'
import Card from '../components/common/card'
import { Label, Input, Help } from '../components/common/form'
import Button from '../components/common/button'

const Register = () => {
  const { firebase } = useContext(FirebaseContext)
  const [username, setUsername] = useState(false)
  const [password, setPassword] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  return (
    <>
      <Card>
        <h1>Get a writey drawey account</h1>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            firebase
              .auth()
              .createUserWithEmailAndPassword(username, password)
              .then((userCredential) => {
                navigate('/')
              })
              .catch((error) => {
                setErrorCode(error.message)
              })
          }}
        >
          <Label htmlFor="register-email">Email address</Label>
          <Input
            type="text"
            name="name"
            id="register-email"
            onChange={(event) => setUsername(event.target.value)}
          />
          <Label htmlFor="register-password">Password</Label>
          <Input
            type="password"
            name="password"
            id="register-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          {errorCode && <Help>{errorCode}</Help>}
          <Button blue type="submit">
            Register
          </Button>
        </form>
      </Card>
    </>
  )
}

export default Register
