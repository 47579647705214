import React, { useEffect, useState, useContext } from 'react'
import { FirebaseContext } from '../contexts/firebase'
import Button from '../components/common/button'
import { navigate } from '@reach/router'
import Directions from '../components/common/directions'
import { Label, Input } from '../components/common/form'
import {
  ComputerPaper,
  ComputerPaperContent,
  ComputerPaperSide,
} from '../components/common/computer-paper'

const JoinForm = ({ game }) => {
  const [userName, setUserName] = useState('')
  const { firebase, firestore, user } = useContext(FirebaseContext)

  useEffect(() => {
    if (!user || !game || typeof user.uid === 'undefined') {
      return
    }
    if (game.players.indexOf(user.uid) > -1) {
      navigate(`/play/${game._id}`)
    }
  }, [game, user])

  if (!game) {
    return null
  }

  return (
    <ComputerPaper flyIn>
      <ComputerPaperSide />
      <ComputerPaperContent>
        {game.status === 'started' ? (
          <p>Sorry, the game already started</p>
        ) : (
          <form
            onSubmit={async (event) => {
              event.preventDefault()
              let login = false
              if (!user) {
                login = await firebase.auth().signInAnonymously()
              }

              firebase.analytics().logEvent('game_joined')
              firebase
                .firestore()
                .collection('games')
                .doc(game._id)
                .update({
                  players: firestore.FieldValue.arrayUnion(
                    !user && login ? login.user.uid : user.uid
                  ),
                  playerNames: firestore.FieldValue.arrayUnion(userName),
                })
                .then(navigate(`/play/${game._id}`))
            }}
          >
            <h1>{game.name}</h1>

            <Label htmlFor="name">Your name</Label>
            <Directions extraMargin>
              Enter the name you'd like to use for this game. It must be at
              least two characters long.
            </Directions>
            <Input
              type="text"
              onChange={(event) => setUserName(event.target.value)}
            />
            <Button blue type="submit" disabled={userName.length < 2}>
              Join{userName.length < 2 && <> (enter a name)</>}
            </Button>
          </form>
        )}
      </ComputerPaperContent>
      <ComputerPaperSide />
    </ComputerPaper>
  )
}

const JoinPage = ({ gameId }) => {
  const [game, setGame] = useState(false)
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot((doc) => {
        setGame({ _id: doc.id, ...doc.data() })
      })
  }, [firebase, gameId])

  return (
    <>
      <JoinForm game={game} />
    </>
  )
}

export default JoinPage
